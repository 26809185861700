/*Fonts*/

@font-face {
	font-family: "Roboto Regeular";
	src: url('../fonts/Roboto-Regular.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto-Regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto-Regular.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Roboto-Regular.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto-Regular.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Roboto Bold";
	src: url('../fonts/Roboto-Bold.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Roboto-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/Roboto-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/Roboto-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Roboto-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Playfair Display Black";
	src: url('../fonts/PlayfairDisplay-Black.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/PlayfairDisplay-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PlayfairDisplay-Black.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/PlayfairDisplay-Black.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/PlayfairDisplay-Black.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PlayfairDisplay-Black.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Helvetica Neue Cyr Bold"; 
	src: url('../fonts/HelveticaNeueCyr-Bold.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/HelveticaNeueCyr-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/HelveticaNeueCyr-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/HelveticaNeueCyr-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/HelveticaNeueCyr-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: "Palatino Linotype Bold"; 
	src: url('../fonts/PalatinoLinotype-Bold.ttf'); /* IE9 Compat Modes */
    src: url('../fonts/PalatinoLinotype-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/PalatinoLinotype-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/PalatinoLinotype-Bold.woff') format('woff'), /* Pretty Modern Browsers */
       url('../fonts/PalatinoLinotype-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/PalatinoLinotype-Bold.svg#svgFontName') format('svg'); /* Legacy iOS */
}

/*Specification*/

body {
	font: 18px 'Roboto Regular', sans-serif;
	color: #000;
	overflow: visible!important;
	margin-right: 0!important;
}

ul, li {
	display: block;
	padding: 0;
	margin: 0;
}

div, h1, h2, h3, h4, h5, h6, p, span, input, button {
	margin: 0;
	padding: 0;
}

p {
	letter-spacing: 0.04em;
}

span {
	display: inline-block;
}

input, button {
	display: block;
	outline: none!important;
	background: transparent;
	border: none;
}

input::-ms-clear {
   display: none;
}

input:invalid {
   -webkit-box-shadow: none;
   box-shadow: none;
}

input:-moz-submit-invalid {
   box-shadow: none;
}

input:-moz-ui-invalid {
   box-shadow:none;
}

button {
	cursor: pointer;
	text-transform: uppercase;
	text-align: center;
	color: #fff;
	font: 16px 'Roboto Bold', sans-serif;	 
}

textarea {
	background: none;
	resize: none;
	outline: none;
}

h2, h3, h4, h5, h6 {
	text-align: center;
}

h1, h2, h3, h5, h6  {
	font-family: 'Roboto Bold', sans-serif;
}

h1, h2, h5 {
	text-transform: uppercase;
}

h2 {
	font-size: 40px;
	color: #e53131;
	text-align: center;
}

h3 {
	font-size: 30px;
}

h4 {
	font-size: 25px;
	color: #585858;
}

h5 {
	font-size: 21px;
}

h6 {
	font-size: 19px;
}

a {
	color: #fff;
}

a, a:hover {
	text-decoration: none;
}

a:hover {
	color: #fff;
}

.transition {
	-webkit-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

/*Header*/

header {
	background: url('../img/header-bg.png') no-repeat top center/cover;
	padding-bottom: 223px;
}

.top-header {
	background-color: rgba(111, 111, 111, 0.6);
	padding: 11px 0;
	position: relative;
	z-index: 90;
}

.top-header.fixed {
	position: fixed;
	top: 0;
	width: 100%;
	background-color: #183910;
}

.logo__text {
	font: 18px 'Roboto Bold', sans-serif;
	color: #cfcfcf;
}

.logo__text span {
	color: #5ea950;
}

.logo__info {
	font-size: 14px;
	margin-top: 3px;
	color: #d6d6d6;
}

.menu__img {
	position: absolute;
	top: -6px;
	left: 0px;
}

.menu__item {
	margin-right:  24px;
}

.menu__item:last-child {
	margin-right: 0;
}

.menu__link {
	text-transform: uppercase;
	font: 14px 'Roboto Bold', sans-serif;
	padding-bottom: 2px;
	-webkit-transition: color .2s ease;
	-o-transition: color .2s ease;
	transition: color .2s ease;
}

.menu__link:hover, .mobile-menu__link:hover {
	color: #4abf32;
	border-bottom: 1px solid #4abf32;
}

.hamburger {
	font-size: 24px;
}

.menu-box {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.95);
}

.mobile-menu {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -130px;
	margin-left: -73.5px;
}

.mobile-menu__item {
	margin-bottom: 30px;
	text-transform: uppercase;
}

.phone__link {
	display: block;
	font: 15px 'Roboto Bold', sans-serif;
	color: #fff;
	margin-bottom: 5px;
}

.phone__link span {
	position: relative;
}

.phone__img {
	position: absolute;
	top: 1px;
	left: -25px;
}

.phone__info {
	font-size: 13px;
	color: #fff;
}

.middle-header {
	display: none;
	position: relative;
	z-index: 90;
}

.middle-header.fixed {
	position: fixed;
	top: 97px;
	width: 100%;
}

.application-info {
	width: 425px;
	background-color: #d9d9d8;
	padding: 14px 0;
	font-size: 15px;
	text-transform: uppercase;
	-webkit-border-radius: 2px;
    border-radius: 2px;
}

.application-info span {
	color: #171a19;
	font-family: 'Roboto Bold', sans-serif
}

.order-btn {
	width: 145px;
	padding: 16px 0;
	-webkit-border-radius: 2px;
    border-radius: 2px;
	background: #d9d9d8 url('../img/icons-contacts/icons-header-phone.png') no-repeat left 7px center;
	font-size: 11px;
	color: #000;
}

.order-btn:hover {
	background-color: #bbbbba;
}

.order-btn span {
	margin-left: 20px;
}

.bot-header {
	margin-top: 165px;
}

.offer {
	color: #fff;
}

.offer__title {
	font-size: 45px;
}

.offer__title span {
	text-transform: none;
}

.offer__info {
	font-size: 25px;
	color: #fff;
	margin-top: 10px;
}

.offer__button {
	width: 252px;
	height: 55px;
	border: 2px solid #49c52e;
	-webkit-border-radius: 2px;
    border-radius: 2px;
	margin-top: 22px;
}

.offer__button:hover {
	background-color: #49c52e;
}

/*Section First*/

#services {
	padding: 83px 0;
	background: url(../img/section-2-bg.png) no-repeat top center/cover;
}

.services-title {
	margin-bottom: 52px;
}

.services-title__span {
	position: relative;
}

.services-title__span:after {
	content: '';
	display: block;
	width: 5px;
	height: 33px;
	background: #e53131;
	position: absolute;
	top: 9px;
}

.services-title__span_left {
	margin-right: 20px;
}

.services-title__span_left:after {
	right: -18px;
}

.services-title__span_right {
	margin-left: 20px;
}

.services-title__span_right:after {
	left: -17px;
}

.equipment {
	width: 275px;
	margin-bottom: 11px;
	padding: 153px 22px 25px 22px;
	-webkit-box-shadow: 9.192px 9.192px 16px 0px rgba(14, 14, 14, 0.24);
    box-shadow: 9.192px 9.192px 16px 0px rgba(14, 14, 14, 0.24);
	-webkit-border-radius: 3px;
    border-radius: 3px;
}

.equipment-1 {
	background: url(../img/icons-washing-machine.png) no-repeat top 28px center;
}

.equipment-1 {
	background: url(../img/icons-washing-machine.png) no-repeat top 28px center;
}
.equipment-2 {
	background: url(../img/icons-fridge.png) no-repeat top 28px center;
}
.equipment-3 {
	background: url(../img/icons-pressure-vessel.png) no-repeat top 28px  center;
}
.equipment-4 {
	background: url(../img/icons-coffee-maker.png) no-repeat top 28px center;
}
.equipment-5 {
	background: url(../img/icons-cooker.png) no-repeat top 28px  center;
}
.equipment-6 {
	background: url(../img/icons-air-conditioner.png) no-repeat top 28px  center;
}

.equipment-7 {
	background: url(../img/icons-dishwasher.png) no-repeat top 28px  center;
}
.equipment-8 {
	background: url(../img/icons-drying-machine.png) no-repeat top 28px  center;
}
.equipment-9 {
	background: url(../img/icons-toaster-oven.png) no-repeat top 28px  center;
}

.equipment__title {
	text-transform: uppercase;
	color: #49c52e;
}

.more-title {
	margin-top: 30px;
}

/* Section Second*/
#advantages {
	background: url('../img/section-3-bg.png') no-repeat top center/cover;
	padding: 95px 0 60px 0;
	color: #fff;
}

.advantages-title {
	color: #fff;
	margin-bottom: 25px;
}

.advantage__icon {
	width: 75px;
	height: 75px;
	border: 2px solid #48be2e;
	-webkit-border-radius: 50%;
    border-radius: 50%;
	margin: 32px auto 12px auto;
}

.advantage__icon_first {
	background: url('../img/icon_3/icons-money.png') no-repeat center center;
}

.advantage__icon_second {
	background: url('../img/icon_3/icons-gears.png') no-repeat center center;
}

.advantage__icon_third {
	background: url('../img/icon_3/icons-house.png') no-repeat center center;
}

.advantage__icon_fourth {
	background: url('../img/icon_3/icons-guarantee.png') no-repeat center center;
}

.advantage__icon_fifth {
	background: url('../img/icon_3/icons-discount.png') no-repeat center center;
}

.advantage__icon_sixth {
	background: url('../img/icon_3/icons-speed.png') no-repeat center center;
}

.advantage__icon_seventh {
	background: url('../img/icon_3/icons-maintenance.png') no-repeat center center;
}

.advantage__icon_eighth {
	background: url('../img/icon_3/icons-purse.png') no-repeat center center;
}

.advantage__icon_ninth {
	background: url('../img/icon_3/icons-good-quality.png') no-repeat center center;
}

.advantage__title {
	margin-bottom: 12px;
}

/*Section Third*/

#confidence{
	padding: 80px 0;
}

.confidance__info {
	color:#585858;
	margin: 14px 0 60px 0;
}

.superiority-1, .superiority-2 {
	margin-top: 35px;
}

.superiority-3 {
	margin-top: 22px;
}

.superiority__title:after {
	content: '';
	display: block;
	width: 100px;
	height: 3px;
	background-color: #49c52e;
	margin: 10px auto 20px auto;
}

.superiority__info {
	margin-left: 15px;
}

.superiority__info span {
	font-family: 'Roboto Bold', sans-serif;
}

/*Section Fourth*/

#result, #order {
	background: rgba(39,159,103,1);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(39,159,103,1)), color-stop(30%, rgba(39,163,107,1)), color-stop(54%, rgba(38,168,112,1)), color-stop(76%, rgba(38,175,116,1)), color-stop(100%, rgba(48,180,122,1)));
    background: -webkit-linear-gradient(left, rgba(39,159,103,1) 0%, rgba(39,163,107,1) 30%, rgba(38,168,112,1) 54%, rgba(38,175,116,1) 76%, rgba(48,180,122,1) 100%);
    background: -o-linear-gradient(left, rgba(39,159,103,1) 0%, rgba(39,163,107,1) 30%, rgba(38,168,112,1) 54%, rgba(38,175,116,1) 76%, rgba(48,180,122,1) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(39,159,103,1)), color-stop(30%, rgba(39,163,107,1)), color-stop(54%, rgba(38,168,112,1)), color-stop(76%, rgba(38,175,116,1)), to(rgba(48,180,122,1)));
    background: linear-gradient(to right, rgba(39,159,103,1) 0%, rgba(39,163,107,1) 30%, rgba(38,168,112,1) 54%, rgba(38,175,116,1) 76%, rgba(48,180,122,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#279f67', endColorstr='#30b47a', GradientType=1 );
}

#result {
	padding: 70px 0 79px 0;
	background-color: #279e68;
	color: #fff;
}

.result__num, .spincrement {
	font: 70px 'Playfair Display Black', serif;
}

.result__num {
	margin-left: 10px;
}

.result__span {
	font-size: 18px;
}

.result__info {
	margin-top: 18px;
	font-size: 20px;
}

/* Section Fifth*/

#customers {
	padding: 80px 0;
}

.customers {
	margin-bottom: 45px;
}

.customers-title {
	margin-bottom: 60px;
}

/*Section Seventh*/

#outline {
	background: url('../img/section-7-bg.png') no-repeat center center/cover;
	padding: 50px 0 30px 0;
	color: #fff;
}

.outline-title, .outline-text {
	color: #fff;
}

.outline-text {
	text-transform: capitalize;
	margin-top: 15px;
}

.outline, .chain {
	text-align: center;
	position: relative
}

.outline-1 {
	margin-bottom: 45px;
}

.outline__num {
	font: 70px 'Palatino Linotype Bold', serif;
	position: relative;
	color: #49c52e;
	text-align: left;
	margin-left: 20px;
	line-height: 0.85em;
}

.outline__num span {
	font: 16px 'Roboto Regular', sans-serif;
	position: absolute;
	top: 2px;
	margin-left: 3px;
}

.outline__arrow {
	position: absolute;
	display: inline-block;
}

.outline__arrow_first {
	-webkit-transform: rotate(20deg);
	-ms-transform: rotate(20deg);
    transform: rotate(20deg);
	right: -50px;
	bottom: 7px;
}

.outline__arrow_second {
	-webkit-transform: rotate(-27deg);
    -ms-transform: rotate(-27deg);
    transform: rotate(-27deg);
	right: -10px;
	top: 42%;
}

.outline__info {
	margin-top: 9px;
}

.outline__info, .chain__info {
	font: 23px 'Roboto Bold', sans-serif; 
}

.chain {
	margin-top: 40px;
	width: 200px;
}

.chain__arrow {
	position: absolute;
	top: 22%;
	display: inline-block;
}

.chain__arrow_first, .chain__arrow_second {
	left: -40px;
}

.chain__arrow_third {
	left: -30px;
}

.chain__info {
	margin-top: 24px;
}

/*Section Eighth*/

#order {
	padding: 53px 0 65px 0;
	color:#fff;
}

.order__title {
	color: #fff;
}

.order__title_big {
	font-size: 35px;
	text-transform: uppercase;
	margin-bottom: 18px;
}

.order__title_middle {
	margin-bottom: 32px;
}

.order-form__input {
	width: 350px;
	height: 57px;
	border: 1px solid #fff;
	padding: 0 15px;
	-webkit-border-radius: 3px;
    border-radius: 3px;
}

.field {
	border: 1px solid #fff;
	padding: 0 15px;
	-webkit-border-radius: 3px;
    border-radius: 3px;
}

.field[placeholder] {
	color: #a8d6be;
	font-size: 20px;  
}

.field::-webkit-input-placeholder {
    color: #a8d6be;
	font-size: 20px; 
}

/* Firefox 19+ */
.field::-moz-placeholder { 
	color: #a8d6be;
	font-size: 20px;
}

/* Firefox 18- */
.field:-moz-placeholder { 
	color: #a8d6be;
	font-size: 20px; 
}

.field:-ms-input-placeholder { 
	color: #a8d6be;
	font-size: 20px; 
}

.button {
	background-color: #49c52e;
	-webkit-border-radius: 3px;
    border-radius: 3px;
	height: 60px;
	-webkit-transition: all 0.2s ease;
	-o-transition: all 0.2s ease;
	transition: all 0.2s ease;
}

.button:hover {
	background-color: #44ad2d;
}

.order-form__button {
	width: 364px;
}

/*Section  Ninth*/
#brands {
	padding: 84px 0;
}

.brands {
	margin-top: 35px;
}

.brands-info {
	margin-top: 50px;
}

/*Section Tenth*/ 

#communic {
	padding: 80px 0;
	background: url('../img/section-search-bg.png') no-repeat top center/cover;
	color: #fff;
}

.communic-title-big, .communic-title-middle {
	color: #fff;
}

.communic-title-middle {
	margin: 15px 0 30px 0;
}

.contacts-list__item, .adress__item, {
	padding: 10px 0 10px 67px;
	font: 19px 'Roboto Bold', sans-serif;
}

.contacts-list__item {
	margin-bottom: 20px;
}

.contacts-list__item_first {
	background: url('../img/icons-contacts/icons-clock.png') no-repeat left center;
}

.contacts-list__item_second {
	background: url('../img/icons-contacts/icons-whatsapp.png') no-repeat left center;
}

.contacts-list__item_third {
	background: url('../img/icons-contacts/icons-phone.png') no-repeat left center;
}

.contacts-list__item_fourth {
	background: url('../img/icons-contacts/icons-message.png') no-repeat left center;
}

.contacts-list__link-mail {
	font-family: 'Roboto Regular', sans-serif;
}

.adress {
	background: url('../img/icons-contacts/icons-address.png') no-repeat left top;
}

.adress__item {
	line-height: 1.57em;
}

.forma {
	background: url('../img/form-bg.png');
	width: 439px;
	margin-top: 10px;
	border: 1px solid #222222;
	-webkit-border-radius: 3px;
    border-radius: 3px;
	padding: 25px 37px 18px 37px;	
}

.forma-header {
	margin-top: 0;
}

.forma-header h4 {
	font-size: 22px;
}

.forma__title {
	font-family: 'Roboto Bold', sans-serif;
}

.forma__title, .forma__text {
	color: #000;
}

.forma__text {
	margin: 18px 0 24px 0;
	font-size: 19px;
}

.forma-header p {
	font-size: 18px;
}

.forma__input, .forma__button {
	width: 358px;
	height: 60px;
	-webkit-border-radius: 2px;
    border-radius: 2px;
}

.forma__input {
	border: 1px solid #969696;
	margin: 0 auto 7px auto;
	padding: 0 15px;
}

.forma__input[placeholder] {
	color: #f1f1f1;
	font-size: 20px;  
}

.forma__input::-webkit-input-placeholder {
    color: #f1f1f1;
	font-size: 20px; 
}

/* Firefox 19+ */
.forma__input::-moz-placeholder { 
	color: #f1f1f1;
	font-size: 20px;
}

/* Firefox 18- */
.forma__input:-moz-placeholder { 
	color: #f1f1f1;
	font-size: 20px; 
}

.forma__input:-ms-input-placeholder { 
	color: #f1f1f1;
	font-size: 20px; 
}

.forma__button {
	margin: 5px auto 18px auto;
}

.forma__info {
	font-size: 14px;
}

.forma__info span {
	padding: 8px 0 0 25px;
	background: url('../img/icons-contacts/icons-lock.png') no-repeat left center;
}

/* Section Eleventh */

.map {
	width: 33.2%;
	border-top: 1px solid #49c52e;
}

.map-2 {
	border-left: 1px solid #49c52e;
	border-right: 1px solid #49c52e;
}

.map-mobile {
	border-top: 1px solid #49c52e;
}

.map-mobile img {
	width: 100%;
}

/*Section Twelfth*/

#appraisal {
	padding: 86px 0;
}

.appraisal__info {
	color: #0b0b0b;
}

.appraisal__info_top {
	margin-top: 17px;
}

.appraisal__button {
	margin: 63px auto 52px auto;
	width: 250px;
	height: 60px;
	position: relative;
}

.appraisal__img {
	position: absolute;
	top: -50%;
	left: -53%;
}

/*Section Thirteenth*/

#reviews {
	background: url('../img/section-reviews.png') no-repeat center center/cover;
	padding: 80px 0 85px 0;
	color: #fff;
}

.reviews-title {
	margin-bottom: 40px;
	color: #fff;
}

.slide {
	margin: 15px 75px;
	outline: none!important;
}

.slide__quotes {
	font: 140px 'Helvetica Neue Cyr Bold', serif;
	color: rgba(11, 11, 11, 0.149);
	position: relative;
	height: 70px;
}

.slide__quotes_top {
	top: -73px;
	left: -30px;
}

.slide__quotes_bot {
	bottom: -40px;
	right: -30px;
}

.slide__photo {
	-webkit-border-radius: 50%;
    border-radius: 50%;
}

.slide__info {
	margin-left: 36px;
}

.slide__title {
	text-align: left;
	margin-bottom: 11px;
}

.slide__text {
	font-size: 16px;
}

.arrows-slider {
	position: absolute;
	top: 40%;
	font-size: 30px;
	color: #49c52e;
	-webkit-transition: all 0.1s ease;
	-o-transition: all 0.1s ease;
	transition: all 0.1s ease;
}

.arrows-slider:hover {
	color: #44ad2d;
	-webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
}

.slider-prew {
	left: -3%;
}

.slider-next {
	right: -3%;
}

.slick-dots li  {
	width: 15px;
	height: 15px;
    border: 1px solid #49c52e;
    margin-right: 6px;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.slick-dots li button:before {
	content: '';
}

li.slick-active {
	background-color:  #49c52e;
}

.slick-dots li:hover {
	background-color:  #49c52e;
}

/*Footer*/

footer {
	background-color: #183910;
	padding: 29px 0 52px 0;
	color: #fff;
}

footer a {
	display: block;
}

.logo-footer__description {
	font-size: 13px;
	line-height: 1.3em;
}

.logo-footer__text {
	letter-spacing: 0.34em;
}

.social {
	margin-top: 16px;
}

.social__item {
	margin-right: 14px;
}

.social__item:last-child {
	margin-right: 0;
}

.confidance {
	margin-top: 20px;
	font: 13px 'Roboto Bold', sans-serif;
}

.confidance span {
	border-right: 1px solid #fff;
	line-height: 0.8em;
	padding-right: 3px;
}

.contacts-footer__item {
	margin-bottom: 20px;
	font-size: 17px;
}

.contacts-footer__item span {
	margin-left: 8px;
	font: 17px 'Roboto Bold', sans-serif;
}

.contacts-footer__info {
	margin-bottom: 10px;
}

.adress-footer__item {
	font-size: 16px;
	line-height: 1.87em;
	margin-bottom: 5px;
}

.btn-up {
	width: 152px;
	height: 37px;
	text-transform: uppercase;
	border: 2px solid #fff;
	-webkit-border-radius: 5px;
    border-radius: 5px;
	text-align: center;
	line-height: 33.7px;
	font-size: 13px;
	position: relative;
}

.btn-up__img {
	position: absolute;
	top: 6px;
}

.btn-up__img_left {
	left: 4px;
}

.btn-up__img_right {
	right: 4px;
}

/* Modal Windows*/

#exampleModal1, #exampleModal2, #thx {
	background: rgba(1,137,74,1);
    background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(1,137,74,1)), color-stop(30%, rgba(1,140,75,1)), color-stop(54%, rgba(0,149,82,1)), color-stop(76%, rgba(0,160,91,1)), color-stop(100%, rgba(36,175,110,1)));
    background: -webkit-linear-gradient(-45deg, rgba(1,137,74,1) 0%, rgba(1,140,75,1) 30%, rgba(0,149,82,1) 54%, rgba(0,160,91,1) 76%, rgba(36,175,110,1) 100%);
    background: -o-linear-gradient(-45deg, rgba(1,137,74,1) 0%, rgba(1,140,75,1) 30%, rgba(0,149,82,1) 54%, rgba(0,160,91,1) 76%, rgba(36,175,110,1) 100%);
    background: -webkit-linear-gradient(315deg, rgba(1,137,74,1) 0%, rgba(1,140,75,1) 30%, rgba(0,149,82,1) 54%, rgba(0,160,91,1) 76%, rgba(36,175,110,1) 100%);
    background: -o-linear-gradient(315deg, rgba(1,137,74,1) 0%, rgba(1,140,75,1) 30%, rgba(0,149,82,1) 54%, rgba(0,160,91,1) 76%, rgba(36,175,110,1) 100%);
    background: linear-gradient(135deg, rgba(1,137,74,1) 0%, rgba(1,140,75,1) 30%, rgba(0,149,82,1) 54%, rgba(0,160,91,1) 76%, rgba(36,175,110,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#01894a', endColorstr='#24af6e', GradientType=1 );
    color: #fff;
}

#exampleModal1, #exampleModal2 {
	width: 855px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-border-radius: 0;
    border-radius: 0;
    padding: 30px 58px 44px 58px;
}
  
.arcticmodal-close {
	color: #3a3a3a;
	font: 18px 'Roboto Bold', sans-serif;
}

.title-modal {
	margin-bottom: 28px;
}

.application-form__input {
	display: inline-block;
	width: 360px;
	height: 60px;
	margin-bottom: 24px;
}

.application-form__input_first {
	margin-right: 15px;
}

.application-form__textarea {
	display: block;
	width: 100%;
	padding-top: 16px;
	padding-bottom: 16px;
	height: 200px;
}

.application-form__button {
	width: 360px;
	margin: 22px auto 0 auto;
	font-size: 16px;
}

#exampleModal3 {
	-webkit-box-shadow: none;
    box-shadow: none;
	-webkit-border-radius: 3px;
    border-radius: 3px;
	background-color: #a0a0a0;
	padding: 8px 17px 8px 7px;
	width: 500px;
}

.form-bell__input {
	width: 220px;
	height: 45px;
	margin-right: 15px;
	background-color: #fff;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	padding: 0 10px;
}

.form-bell__button {
	width: 220px;
	height: 45px;
	text-transform: none;
	font-size: 15px;
}

.close-modal {
	font-size: 16px;
	top: 3px;
	right: 7px;
	color: #737373;
}

.form-bell__input[placeholder] {
	font: 14px 'Roboto Bold', sans-serif;
	color: #c0c0c0;  
}

.form-bell__input::-webkit-input-placeholder { 
	font: 14px 'Roboto Bold', sans-serif;
	color: #c0c0c0;
}

	/* Firefox 19+ */
.form-bell__input::-moz-placeholder { 
	font: 14px 'Roboto Bold', sans-serif;
	color: #c0c0c0;
	line-height: 45px;
}

	/* Firefox 18- */
.form-bell__input:-moz-placeholder { 
	font: 14px 'Roboto Bold', sans-serif;
	color: #c0c0c0;
	line-height: 45px; 
}

.form-bell__input:-ms-input-placeholder { 
	font: 14px 'Roboto Bold', sans-serif;
	color: #c0c0c0;
}

.error {
	display: block;
	font-size: 12px;
	margin-bottom: 5px;
}

#loader {
	display: none;
	background-color: rgba(0, 0, 0, 0.6);
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

#loader img {
    position: absolute;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -50px;
}

#overlay {
	display: none;
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	cursor: pointer;
	z-index: 100;
}

#thx {
	background: rgba(1,141,78,1);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(1,141,78,1)), color-stop(30%, rgba(0,143,79,1)), color-stop(54%, rgba(1,148,79,1)), color-stop(76%, rgba(0,161,91,1)), color-stop(100%, rgba(3,164,97,1)));
    background: -webkit-linear-gradient(left, rgba(1,141,78,1) 0%, rgba(0,143,79,1) 30%, rgba(1,148,79,1) 54%, rgba(0,161,91,1) 76%, rgba(3,164,97,1) 100%);
    background: -o-linear-gradient(left, rgba(1,141,78,1) 0%, rgba(0,143,79,1) 30%, rgba(1,148,79,1) 54%, rgba(0,161,91,1) 76%, rgba(3,164,97,1) 100%);
    background: -webkit-gradient(linear, left top, right top, from(rgba(1,141,78,1)), color-stop(30%, rgba(0,143,79,1)), color-stop(54%, rgba(1,148,79,1)), color-stop(76%, rgba(0,161,91,1)), to(rgba(3,164,97,1)));
    background: linear-gradient(to right, rgba(1,141,78,1) 0%, rgba(0,143,79,1) 30%, rgba(1,148,79,1) 54%, rgba(0,161,91,1) 76%, rgba(3,164,97,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#018d4e', endColorstr='#03a461', GradientType=1 );
	width: 856px;
	text-align: center;
	position: relative;
	left: 50%;
	margin-left: -428px;
	top: 50%;
	margin-top: -66px;
	z-index: 100;
	padding: 30px 0;
	line-height: 1.3em;
}

/*Responsive*/

@import '_media-main.scss';


