/*Responsive*/

@media screen and (min-width: 1200px) {
	
	.container {
		max-width: 1170px;
	}

}

@media screen and (max-width: 1199px) {
	.menu__img {
		left: -17px;
	}

	.menu__item {
		margin-right: 18px;
	}

	.logo__info, .menu__link, .phone__info {
		font-size: 12px;
	} 

	h2 {
		font-size: 37px;
	}

	.middle-header.fixed {
		top: 108px;
	}

	.offer__title {
		font-size: 41px;
	}

	.superiority-img {
		width: 100%;
	}

	.superiority__info {
		margin-left: 0;
	}

	.superiority-1, .superiority-2 {
		margin: 0 0 25px 0;
	}

	.outline__arrow_first {
		right: -50px;
	}

	.outline__arrow_second {
		right: -30px;
	}

	.chain__arrow_first, .chain__arrow_second {
		left: -30px;
	}

	.chain__arrow_third {
		left: -25px;
	}

	.outline__info, .chain__info {
		font-size: 18px;
	}

	.order-form__input, .order-form__button  {
		width: 280px;
	}

	.slide__quotes {
		display: none;
	}
}

@media screen and (max-width: 991px) {
	header {
		padding-bottom: 170px;
	}

	.offer__button {
		margin: 22px auto 0 auto;
	}

	.services-title__span_right {
		margin-left: 0;
	}

	.equipment-9 {
		width: 100%;
	}

	.superiority {
		margin-top: 30px;
	}

	.outline {
		width: 220px;
		margin: 15px 0 0 0;
	}

	.outline__num_first {
		margin-bottom: 17px;
	}

	.outline__arrow {
		position: static;
		margin-top: 25px;
	}

	.outline__arrow_first {
		-webkit-transform: rotate(50deg);
	    -ms-transform: rotate(50deg);
        transform: rotate(50deg);
	}

	.outline__arrow_second {
		-webkit-transform: rotate(125deg);
	    -ms-transform: rotate(125deg);
        transform: rotate(125deg);
	}

	.chain {
		width: auto;
	}

	.chain__arrow {
		position: static;
		-webkit-transform: rotate(90deg);
	    -ms-transform: rotate(90deg);
        transform: rotate(90deg);
		margin-top: 30px;
	}

	.chain__arrow_third {
		display: none;
	}

	.order-form__input {
		margin: 0 auto 25px auto;
	}

	.order-form__button {
		margin: auto;
	}

	.forma {
		margin-top: 30px;
	}

	.adress {
		margin-top: 35px;
	}

	.btn-up {
		margin: 35px 0 35px 0;
	}

	.bot-header {
		margin-top: 80px;
	}

	#exampleModal1, #exampleModal2 {
		width: 700px;
	}

	#thx {
		width: 520px;
		margin-left: -260px;
	}

	.application-form__input {
		display: block;
		margin: 0 auto 25px auto;
	}

	.application-form__textarea {
		width: 360px;
		margin: auto;
	}

	label {
		text-align: center;
	}
}

@media screen and (max-width: 768px) {
	header {
		background: url(../img/header-mobile.png) no-repeat top center/cover;
	}
	
	.application-info {
		width: 100%;
		margin-bottom: 10px;
	}

	.offer {
		margin-top: 80px;
	}

	.middle-header.fixed {
		top: 93px;
	}

	.order-btn {
		width: 30px;
	}

	.order-btn span {
		display: none;
	}

	.equipment-9 {
		width: 275px;
	}

	.services-title__span_left {
		margin-right: 0;
	}

	.services-title__span:after, .slide__quotes, .arrows-slider, .appraisal__img  {
		display: none!important;
	}

	.slide {
		margin: 0 20px;
	}

	.slide__photo {
		margin-bottom: 25px;
	}	

	.slide__info {
		margin-left: 0;
	}

	.slide__title, .slide__text {
		text-align: center;
	}

	#exampleModal1, #exampleModal2 {
		width: auto;
	}
}

@media screen and (max-width: 576px) {
	.application-info {
		font-size: 11px;
	}

	.offer__title {
		font-size: 32px;
	}

	.offer__info {
		font-size: 19px;
	}

	h2 {
		font-size: 23px;
	}

	h3 {
		font-size: 22px;
	}

	h4 {
		font-size: 19px;
	}

	.logo__info {
		font-size: 11px;
	}

	.order__title_big {
		font-size: 21px;
	}

	.services-title {
		margin-bottom: 30px;
	}

	.result__num, .spincrement {
		font-size: 55px;
	}

	.result__info {
		font-size: 18px;
	}

	.brands {
		margin-top: 15px;
	}

	.brands-info {
		margin-top: 30px;
	}

	.adress {
		margin-top: 15px;
	}

	.contacts-list__item, .adress__item, {
		padding:15px 0 10px 55px;
		font-size: 14px;
	}

	.contacts-list__item {
		margin-bottom: 10px;
	}

	.forma {
		width: 100%;
		height: auto;
		padding: 25px 15px 18px 15px;
	}

	.forma__input, .forma__button {
		width: 100%;
	}

	.bot-header {
		margin-top: 30px;
	}

	.application-form__input, .application-form__textarea, .application-form__button {
		width: 100%;
	}

	.application-form__input {
		height: 55px;
	}

	.application-form__textarea {
		height: 150px;
	}

	.field[placeholder], .forma__input[placeholder] {
		font-size: 16px;  
	}

	.field::-webkit-input-placeholder, .forma__input::-webkit-input-placeholder { 
		font-size: 16px; 
	}

	/* Firefox 19+ */
	.field::-moz-placeholder, .forma__input::-moz-placeholder { 
		font-size: 16px;
	}

	/* Firefox 18- */
	.field:-moz-placeholder, .forma__input:-moz-placeholder { 
		font-size: 16px; 
	}

	.field:-ms-input-placeholder, .forma__input:-ms-input-placeholder { 
		font-size: 16px; 
	}

	.button	{
		font-size: 15px;
	}

	#thx {
		width: 300px;
		margin-left: -150px;
		margin-top: -82px;
	}

	#exampleModal3 {
		padding: 20px;
		width: auto;
	}

	.form-bell__input {
		width: 150px;
		margin: 10px auto;
	}
	
	.form-bell__button {
		width: 110px;
		margin: 0 auto 10px auto;
	}

	.close-modal {
		font-size: 12px;
		top: 1px;
	}
}

@media screen and (max-width: 480px) {
	.offer__title {
		font-size: 26px;
	}

	.outline {
		width: 140px;
	}

	.outline__num {
		font-size: 50px;
		margin: 0 0 10px 0;
	}

	.outline__num_first {
		margin-bottom: 27px;
	}

	.outline__info, .chain__info {
		font-size: 17px;
	}

	#exampleModal1, #exampleModal2 {
		padding: 30px 20px 44px 20px;
	}
}

@media screen and (max-width: 325px) {
	.middle-header.fixed {
		top: 93px;
	}
}

